import * as React from "react"

import Layout from "../components/layout"
import Form from "../components/form"

const IndexPage = () => {
  return (
    <>
      <div className="content w-full p-12">
        <div id="profile" className="prose">
          <h2 className="section-headline">Profile</h2>
          <p>
            Hello! I am Jose, I am a senior software engineer with a passion for clean code. 
            Experienced in the energy and health care markets, security and maintainability are my priorities.  
            I have designed solutions from infrastructure, to back end applications and front end.
          </p>
          <hr className="mt-8 mb-12" />
          <p>
            I enjoy working in teams and contributing with people.
            Its important for me to always keeps learning from my interactions with coworkers.
          </p>
        </div>
      </div>
      <div className="content w-full p-12">
        <div id="activities" className="prose">
          <h2 className="section-headline">Activities & Hobbies</h2>
          <p>
            I enjoy playing guitar, make small indie games, and scuba diving.
          </p>
          <p>
            I do a lot of self training for my own advancement from sources like Plural Sight and Coursera.
          </p>
        </div>
      </div>
      <div className="content w-full p-12"><Form/></div>      
    </>
  )
}

IndexPage.Layout = Layout

export default IndexPage